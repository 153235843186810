import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const DataCenterDashboards = loadable(() => import("@/pages/DataCenter/DashboardTab")); // prettier-ignore
const DataCenterReportBuilder = loadable(() => import("@/pages/DataCenter/ReportBuilderTab")); // prettier-ignore
const DataCenterViewDashboard = loadable(() => import("@/pages/DataCenter/ViewDashboard")); // prettier-ignore

export enum EHRDataCenterRoutes {
  DataCenterDashboards = '/data-center/dashboards',
  DataCenterReportBuilder = '/data-center/report-builder',
  DataCenterViewDashboard = '/data-center/dashboards/:explo_dashboard_id'
}

export const getEhrDataCenterRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;

  if (
    !FeatureFlagService.isEnabled(ExacareFeature.DATA_CENTER_TAB) ||
    !currentUser.isDataCenterEnabled()
  ) {
    return [];
  }

  return [
    {
      path: EHRDataCenterRoutes.DataCenterDashboards,
      element: <DataCenterDashboards />
    },
    {
      path: EHRDataCenterRoutes.DataCenterViewDashboard,
      element: <DataCenterViewDashboard />
    },
    {
      path: EHRDataCenterRoutes.DataCenterReportBuilder,
      element: <DataCenterReportBuilder />
    }
  ];
};
