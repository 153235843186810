import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

const AdminHubProviders = loadable(() => import("@/pages/AdminHub/Providers/Providers")); // prettier-ignore
const AdminHubDoctorContacts = loadable(() => import("@/pages/AdminHub/Providers/DoctorContacts")); // prettier-ignore
const AdminHubFuneralHomes = loadable(() => import("@/pages/AdminHub/Providers/FuneralHomes")); // prettier-ignore
const AdminHubHospitals = loadable(() => import("@/pages/AdminHub/Providers/Hospitals")); // prettier-ignore
const AdminHubOtherProviders = loadable(() => import("@/pages/AdminHub/Providers/OtherProviders")); // prettier-ignore
const AdminHubPharmacies = loadable(() => import("@/pages/AdminHub/Providers/Pharmacies")); // prettier-ignore

export enum AdminHubProviderRoutes {
  AdminHubProviders = '/admin-hub/providers',
  AdminHubDoctorContacts = '/admin-hub/providers/doctor-contacts',
  AdminHubPharmacies = '/admin-hub/providers/pharmacies',
  AdminHubHospitals = '/admin-hub/providers/hospitals',
  AdminHubFuneralHomes = '/admin-hub/providers/funeral-homes',
  AdminHubOtherProviders = '/admin-hub/providers/other-providers'
}

export const getEhrAdminHubProviderRoutes = (): RouteObject[] => {
  return [
    {
      path: AdminHubProviderRoutes.AdminHubProviders,
      element: <AdminHubProviders />,
      children: [
        {
          path: AdminHubProviderRoutes.AdminHubDoctorContacts,
          element: <AdminHubDoctorContacts />
        },
        {
          path: AdminHubProviderRoutes.AdminHubPharmacies,
          element: <AdminHubPharmacies />
        },
        {
          path: AdminHubProviderRoutes.AdminHubHospitals,
          element: <AdminHubHospitals />
        },
        {
          path: AdminHubProviderRoutes.AdminHubFuneralHomes,
          element: <AdminHubFuneralHomes />
        },
        {
          path: AdminHubProviderRoutes.AdminHubOtherProviders,
          element: <AdminHubOtherProviders />
        }
      ]
    }
  ];
};
